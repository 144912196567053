<template>
    <div class="container" id="content">
      <div class="title">
        <div class="title-top">
          <div class="el-title-item">
            <el-form ref="form" label-width="80px">
              <el-form-item label="">
                <el-button type="success" size="small" @click="addOption">新增</el-button>
              </el-form-item>
              <!-- <el-form-item label="" style="margin-left: 2vw">
                <el-input v-model="searchName" placeholder="请输入名称"></el-input>
              </el-form-item> -->
            </el-form>
          </div>
          <!-- <el-button style="margin-left: 1vw;" type="success" size="small" @click="search">搜索</el-button> -->
        </div>
      </div>
      <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%; margin-top: 1vh;">
        <el-table-column show-overflow-tooltip label="序号" align="center" :index="indexMethod" width="100"
        type="index"></el-table-column>
        <el-table-column prop="productName" label="产品名称" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column prop="productInventoryNumber" label="库存编号" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column prop="saleUnit" label="产品规格" show-overflow-tooltip align="center"></el-table-column>
        <!-- <el-table-column prop="warehousingUnit" label="入库规格" show-overflow-tooltip align="center"></el-table-column> -->
        <el-table-column prop="inventoryLocation" label="库存位置" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column prop="saleCount" label="销售量" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column prop="salePrice" label="销售金额" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column prop="salesDirection" label="销售流向" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column prop="principal" label="负责人" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column label="操作" align="center" width="150">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              icon="el-icon-edit"
              @click="edits(scope.row)"
            ></el-button>
            <!-- <el-button
              type="primary"
              size="small"
              icon="el-icon-delete"
              @click="deletes(scope.row.id)"
            ></el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="block pagingBox">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>
      </div>
      <!--    编辑-->
      <el-dialog title="" :visible.sync="messageBox" width="31vw" class="addAlameBox">
        <div slot="title" class="header-title" style="position: relative">
          <span class="title-name"></span>
          <span class="title-age">{{ formTitle }}</span>
          <span style=" position: absolute; top: 50%; transform: translateY(-50%); right: 0px; font-size: 2.22vh;"></span>
        </div>
        <div class="addAlaForm">
          <el-form ref="addForm" :rules="rules" :model="addForm" label-width="11vw">
            <el-form-item label="产品名称" prop="productName">
              <!-- <el-date-picker v-model="addForm.nyr" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker> -->
              <el-input disabled v-model="addForm.productName" placeholder="请输入产品名称"></el-input>
            </el-form-item>
            <el-form-item label="库存编号" prop="productInventoryNumber">
                <el-select :disabled="editMse" v-model="addForm.productInventoryNumber" placeholder="请选择库存编号" @change="wareHouseChang">
                    <el-option v-for="self in harvestData" :key="self.id" :label="self.productInventoryNumber" :value="self.id"></el-option>
                </el-select>
              <!-- <el-input v-model="addForm.harvestNumber" placeholder="请输入作物类型"></el-input> -->
            </el-form-item>
           
            <el-form-item label="产品规格" prop="saleUnit">
              <el-input disabled v-model="addForm.saleUnit" placeholder="请输入产品规格"></el-input>
            </el-form-item>
            <el-form-item label="库存位置" prop="inventoryLocation">
              <!-- <el-date-picker v-model="addForm.nyr" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker> -->
              <el-input disabled v-model="addForm.inventoryLocation" placeholder="请输入库存位置"></el-input>
            </el-form-item>
            <el-form-item label="销售量" prop="saleCount">
              <el-input v-model="addForm.saleCount" placeholder="请输入销售量"></el-input>
            </el-form-item>
            <el-form-item label="销售金额" prop="salePrice">
              <el-input v-model="addForm.salePrice" placeholder="请输入销售金额"></el-input>
            </el-form-item>
            <el-form-item label="销售流向" prop="salesDirection">
              <el-input v-model="addForm.salesDirection" placeholder="请输入销售流向"></el-input>
            </el-form-item>
            <el-form-item label="负责人" prop="principal">
              <el-input v-model="addForm.principal" placeholder="请输入负责人"></el-input>
            </el-form-item>
            <el-form-item label="销售时间" prop="saleTime">
                <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="addForm.saleTime" type="datetime" placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer" v-show="showFlag">
            <el-button type="info" @click="messageBox = false">取消</el-button>
            <el-button type="primary" @click="saveForm('addForm')">确定</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </template>
  <script>
  export default {
    // name: 'sensorManagement',
    data() {
      return {
        tableData: [],
        page: 0,
        size: 10,
        total: 0,
        loading: false,
        ids: 0,
        searchName: '',
        addForm: {},
        rules: {
            productName: [
                {required: true, message: "产品名称不可为空", trigger: ["blur", "change"]},
            ],
            productInventoryNumber: [
                {required: true, message: "产品编号不可为空", trigger: ["blur", "change"]},
            ],
            saleUnit: [
                {required: true, message: "产品规格不可为空", trigger: ["blur", "change"]},
            ],
            inventoryLocation: [
                {required: true, message: "库存位置不可为空", trigger: ["blur", "change"]},
            ],
            saleCount: [
                {required: true, message: "销售量不可为空", trigger: ["blur", "change"]},
            ],
            salePrice: [
                {required: true, message: "销售金额不可为空", trigger: ["blur", "change"]},
            ],
            salesDirection: [
                {required: true, message: "销售流向不可为空", trigger: ["blur", "change"]},
            ],
            principal: [
                {required: true, message: "负责人不可为空", trigger: ["blur", "change"]},
            ],
            saleTime: [
                {required: true, message: "销售时间不可为空", trigger: ["blur", "change"]},
            ],
          
        },
        formTitle: "",
        messageBox: false,
        showFlag: false,
        // value: true,
        pageSize: 8,
        pageNow: 1,
        cImgfileList:[],
        harvestData: [],
        editMse: false,
      };
    },
    mounted() {
      this.getList();
      this.getHarvestData();
    },
    methods: {
        // 序号
        indexMethod(index) {
        return index + this.pageSize * (this.pageNow - 1) + 1;
        },
      //请求列表数据
      getList() {
        this.$get("/productsSale/getProductsSales", {
          page: this.page,
          size: this.size,
        //   cropName: this.searchName,
        }).then((res) => {
          if (res.data.state == "success") {
            this.tableData = res.data.datas;
            this.total = res.data.count;
            //console.log("获取列表", this.tableData)
          }
        });
      },
    //   获取库存列表
      getHarvestData(){
        this.$get("/productWarehousing/getProductWarehousingList", {
          page: 1,
          size: 10000,
        }).then((res) => {
          if (res.data.state == "success") {
            this.harvestData = res.data.datas;
            //console.log("获取列表", this.tableData)
          }
        });
      },
      wareHouseChang(val){
        //console.log(val);
        for (let n = 0; n < this.harvestData.length; n++) {
            if (this.harvestData[n].id ==val) {
                this.addForm.productName = this.harvestData[n].productName;
                this.addForm.saleUnit = this.harvestData[n].inventoryUnit;
                this.addForm.inventoryLocation = this.harvestData[n].inventoryLocation;
                this.addForm.productInventoryNumber = this.harvestData[n].productInventoryNumber;
            }
            
        }
      },
      // 编辑
      edits(row) {
        //console.log(row);
        this.showFlag = true;
        this.editMse = true;
        this.ids = row.id;
        this.formTitle = "编辑";
        this.messageBox = true;
        this.addForm = JSON.parse(JSON.stringify(row));
      },
      // 删除
      deletes(id) {
        // 弹出提示是否删除
        this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$delete(`/productWarehousing/delProductWarehousing/${id}`).then((res) => {
              if (res.data.state == "success") {
                this.$message.success("删除成功");
                this.getList();
              } else {
                this.$message.error("删除失败");
              }
            });
          })
          .catch((e) => e);
      },
      // 确认提交
      saveForm(formName) {
        // let id = this.ids;
        this.addForm.id = this.ids;
        this.addForm.createUser = window.localStorage.getItem('user')
        //console.log(this.addForm);
        let url = '/productsSale/addOrUpdateProductsSale';
        if(this.formTitle == '新增'){
          delete this.addForm.id;
        }
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$postJSON(url, this.addForm
            ).then((res) => {
              if (res.data.state == 'success') {
                if(this.formTitle == '新增'){
                  this.$message.success('添加成功')
                }else{
                  this.$message.success('修改成功')
                }    
                this.messageBox = false
                this.getList();
              } else {
                this.$message.error(res.data.msg)
                this.messageBox = false
              }
            })
          } else {
            // //console.log('error submit!!');
            return false;
          }
        });
      },
      // 新增
      addOption() {
        this.formTitle = "新增";
        this.messageBox = true;
        this.editMse = false;
        this.showFlag = true;
        this.$nextTick(() => {
          this.addForm = {};
          this.$refs.addForm.resetFields();
        });
      },
      // 搜索
      search() {
        this.page = 1
        this.getList();
      },
      handleSizeChange(val) {
        this.size = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.page = val;
        this.getList();
      },

      beforeAvatarUpload2(val) {
      const fileLimitSize = 2;// 几兆限制
      const isJPG = val.type === "image/jpeg";
      const isJPGs = val.type === "image/png";
      const fileLimit = val.size / 1024 / 1024 < fileLimitSize;
      if (!isJPG && !isJPGs) {
        this.$message.info("上传图片格式只能为 JPG 或 PNG 格式！");
        this.fileList2 = [];
        return false
      }
      if (!fileLimit) {
        this.$message.info(`上传图片大小不能超过${fileLimitSize}MB!`);
        this.fileList2 = [];
        return false
      }

      return true;
    },
    handleAvatarSuccess2(res, file) {
        this.cImgfileList.push(file);
        let data = [];
        for(let a = 0; a < this.cImgfileList.length; a++){
            data.push("https://public.half-half.cn/" + this.cImgfileList[a].response.data.fileName)
        }
        this.addForm.productImg = data.join(",")
    //   this.addForm.imgUrl =
    //     "https://public.half-half.cn/" + res.data.fileName;
        //console.log(this.addForm.productImg)
    },
    },
  };
  </script>
  
  <style scoped lang="less">
  @import "../../../style/backTable.less";
  </style>
  <style scoped>
  #content /deep/ .el-table th {
    background-color: rgba(240, 242, 245, 1) !important;
  }
  
  #content /deep/ .el-table {
    max-height: 78vh;
    overflow-y: scroll;
  }
  
  #content /deep/ .el-table::-webkit-scrollbar {
    display: none;
  }
  
  .addAlaForm /deep/ .el-select {
    width: 100% !important;
  }
  
  .dialog-footer {
    height: 5vh;
    text-align: right;
  }
  
  .dialog-footer /deep/ .el-button, .el-button--info {
    margin-left: 10px !important;
  
  }
  
  .dialog-footer /deep/ .el-button--info {
    background-color: transparent !important;
    color: #000;
  }
  
  .title /deep/ .el-input__inner {
    height: 33px !important;
  }
  
  .drawerBox /deep/ .msg {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1vh 1vw;
  }
  
  </style>
  
  
  